@import url("./assets/style/animation.css");


@font-face {
  font-family: "Axiforma";
  src: url("assets/fonts/axiforma/axiforma-regular-webfont.woff") format("woff2"),
    url("assets/fonts/axiforma/axiforma-regular-webfont.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Axiforma";
}

@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon/icomoon.eot?ymqxoh');
  src: url('assets/fonts/icomoon/icomoon.eot?ymqxoh#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon/icomoon.woff2?ymqxoh') format('woff2'),
    url('assets/fonts/icomoon/icomoon.ttf?ymqxoh') format('truetype'),
    url('assets/fonts/icomoon/icomoon.woff?ymqxoh') format('woff'),
    url('assets/fonts/icomoon/icomoon.svg?ymqxoh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* min-width: 1280px */
}

body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  line-height: initial;
}

input::placeholder {
  color: #91A7CC;
}

progress::-webkit-progress-bar {
  background-color: #eee;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: 0.5s ease;
}

progress::-webkit-progress-value {
  background-color: #273238;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: 0.5s ease;
}

progress::-moz-progress-bar {
  background-color: #eee;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: 0.5s ease;
}

progress::-webkit-progress-value {
  background-color: #b9ff47;
  transition: 0.5s ease;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

textarea {
  resize: none !important;
}

html {
  background: rgb(19, 26, 38)
}

/* TODO: Remove this css */
.ant-notification-notice-message {
  color: #92A6C8 !important
}


.icon-rankings-no-data:before {
  content: "\e973";
}

.icon-get-coins:before {
  content: "\e969";
}

.icon-ask:before {
  content: "\e971";
}

.icon-users:before {
  content: "\e96c";
}

.icon-mail-open:before {
  content: "\e96e";
}

.icon-map-pin:before {
  content: "\e96f";
}

.icon-phone:before {
  content: "\e970";
}

.icon-restart:before {
  content: "\e972";
}

.icon-onlystar:before {
  content: "\e96d";
}

.icon-transfer:before {
  content: "\e968";
}

.icon-transactions:before {
  content: "\e96a";
}

.icon-wallet:before {
  content: "\e96b";
}

.icon-settings:before {
  content: "\e935";
}

.icon-no-event:before {
  content: "\e955";
}

.icon-remote:before {
  content: "\e966";
}

.icon-Group:before {
  content: "\e900";
}

.icon-active-home:before {
  content: "\e964";
}

.icon-active-video:before {
  content: "\e963";
}

.icon-play-nofill:before {
  content: "\e962";
}

.icon-chat-off:before {
  content: "\e960";
}

.icon-star:before {
  content: "\e961";
}

.icon-event-rankings-off:before {
  content: "\e920";
}

.icon-copied:before {
  content: "\e91d";
}

.icon-microphone-off:before {
  content: "\e924";
}

.icon-rankings-filled:before {
  content: "\e95f";
}

.icon-checkered-flag:before {
  content: "\e95a";
}

.icon-circle-user-camera:before {
  content: "\e95b";
}

.icon-pause1:before {
  content: "\e95c";
}

.icon-square-ad:before {
  content: "\e95d";
}

.icon-gamepad:before {
  content: "\e95e";
}

.icon-bell:before {
  content: "\e959";
}

.icon-zapapa:before {
  content: "\e936";
}

.icon-report:before {
  content: "\e958";
}

.icon-share:before {
  content: "\e946";
}

.icon-full-screen:before {
  content: "\e957";
}

.icon-bell-fill:before {
  content: "\e952";
}

.icon-chat-fill:before {
  content: "\e956";
}

.icon-no-followers:before {
  content: "\e953";
}

.icon-no-follow:before {
  content: "\e954";
}

.icon-signout:before {
  content: "\e941";
}

.icon-block-guard:before {
  content: "\e942";
}

.icon-group-chat:before {
  content: "\e943";
}

.icon-hate-speech:before {
  content: "\e944";
}

.icon-sexuality:before {
  content: "\e945";
}

.icon-fraud:before {
  content: "\e947";
}

.icon-3dot:before {
  content: "\e948";
}

.icon-camera-off:before {
  content: "\e949";
}

.icon-moderator-off:before {
  content: "\e94a";
}

.icon-view:before {
  content: "\e94b";
}

.icon-follow:before {
  content: "\e94c";
}

.icon-trending:before {
  content: "\e94d";
}

.icon-view-off:before {
  content: "\e94e";
}

.icon-view1:before {
  content: "\e94f";
}

.icon-envelope:before {
  content: "\e950";
}

.icon-lock:before {
  content: "\e951";
}

.icon-Comedy:before {
  content: "\e93a";
}

.icon-Creative:before {
  content: "\e93b";
}

.icon-Education:before {
  content: "\e93c";
}

.icon-Food:before {
  content: "\e93d";
}

.icon-Game:before {
  content: "\e93e";
}

.icon-Music:before {
  content: "\e93f";
}

.icon-Politics:before {
  content: "\e940";
}

.icon-stream1:before {
  content: "\e939";
}

.icon-Chat:before {
  content: "\e938";
}

.icon-left-arrow:before {
  content: "\e937";
}

.icon-whatsapp:before {
  content: "\e901";
}

.icon-block:before {
  content: "\e967";
}

.icon-trash:before {
  content: "\e934";
}

.icon-user:before {
  content: "\e902";
}

.icon-chat:before {
  content: "\e903";
}

.icon-search:before {
  content: "\e904";
}

.icon-home:before {
  content: "\e905";
}

.icon-video:before {
  content: "\e906";
}

.icon-rankings:before {
  content: "\e907";
}

.icon-signin:before {
  content: "\e908";
}

.icon-money:before {
  content: "\e909";
}

.icon-user-group:before {
  content: "\e90a";
}

.icon-calendar:before {
  content: "\e90b";
}

.icon-play:before {
  content: "\e90c";
}

.icon-clock:before {
  content: "\e90d";
}

.icon-analog:before {
  content: "\e90e";
}

.icon-remove:before {
  content: "\e90f";
}

.icon-camera:before {
  content: "\e910";
}

.icon-stream:before {
  content: "\e911";
}

.icon-emoji:before {
  content: "\e912";
}

.icon-arrow-down:before {
  content: "\e913";
}

.icon-arrow-up:before {
  content: "\e914";
}

.icon-arrow-right:before {
  content: "\e915";
}

.icon-arrow-left:before {
  content: "\e916";
}

.icon-image:before {
  content: "\e917";
}

.icon-tutorial:before {
  content: "\e918";
}

.icon-gift-wheel:before {
  content: "\e919";
}

.icon-poll:before {
  content: "\e91a";
}

.icon-edit:before {
  content: "\e91b";
}

.icon-advertising:before {
  content: "\e91c";
}

.icon-event-rankings:before {
  content: "\e91e";
}

.icon-moderator:before {
  content: "\e91f";
}

.icon-add-people:before {
  content: "\e921";
}

.icon-message:before {
  content: "\e922";
}

.icon-screen:before {
  content: "\e923";
}

.icon-screen-off:before {
  content: "\e925";
}

.icon-microphone:before {
  content: "\e926";
}

.icon-play-fill:before {
  content: "\e927";
}

.icon-pause:before {
  content: "\e928";
}

.icon-check-mark:before {
  content: "\e929";
}

.icon-close:before {
  content: "\e92a";
}

.icon-plus-image:before {
  content: "\e92b";
}

.icon-check-play:before {
  content: "\e92c";
}

.icon-twitter:before {
  content: "\e92d";
}

.icon-instagram:before {
  content: "\e92e";
}

.icon-youtube:before {
  content: "\e92f";
}

.icon-facebook:before {
  content: "\e930";
}

.icon-plus:before {
  content: "\e931";
}

.icon-dollar:before {
  content: "\e932";
}

.icon-copy:before {
  content: "\e933";
}

.icon-send:before {
  content: "\e965";
}