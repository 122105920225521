@keyframes bounce-4 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  80% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes buttonAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes TransitioningBackground {
  0% {
    transform: translateX(-100px) skewX(-15deg);
  }

  50% {
    transform: translateX(100px) skewX(-15deg);
  }

  100% {
    transform: translateX(-100px) skewX(-15deg);
  }
}

@keyframes searchdropdown {
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 14rem;
  }
}

@keyframes lineAnim {
  0% {
    left: -100%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes adCountdown {
  0% {
    background-color: red;
  }

  100% {
    background-position: 0 -100%;
  }
}

@keyframes circle1 {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.4);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(0.9);
    opacity: 0.2;
  }

  70% {
    -webkit-transform: scale(1.1);
    opacity: 0.35;
  }

  80% {
    -webkit-transform: scale(1.25);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes color-change {
  0% {
    color: white;
  }

  50% {
    color: #ffd747;
  }

  100% {
    color: white;
  }
}

@keyframes shakers {

  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(5px, 0, 0);
  }
}

@keyframes slide-to-left {
  0% {
    margin-right: -200px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}